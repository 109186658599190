<template>
  <FilterForm :formData="filterData" :filters="filters" @closeUp="closeUp" @unflod="unflod" @submit="handleSubmit"
    @reset="handleReset" @changeDept="changeDept" />
  <div class="distribution-btn">
    <el-button type="primary" size="small" @click="handlePublicPool">移入公海</el-button>
    <el-button type="primary" size="small" @click="handleImport">移入重要客户</el-button>
    <el-button type="primary" size="small" @click="handleRubbish">放入垃圾库</el-button>
    <el-button v-if="buttons.includes('分配按钮')" type="primary" size="small" @click="handleDistribution">分配给</el-button>
  </div>
  <AllCustomerTable :tableColumn="tableColumn" :tableData="tableData" :selection="true"
    @selection="handleSelectionChange" @followRecords="followRecords" @nameDrawer="handleNameDrawer" />
  <AllCustomerPagination :total="total" @currentChange="currentChange" @sizeChange="sizeChange" />
  <DistributionDialog title="分配给" :isShowDialog="isShowDialog" :forms="forms" :formData="formData" :width="500"
    @submit="handleDistributionSubmit" @close="handleClose" />
  <DialogFollowRecords :isShowDialog="isShowFollow" :width="500" @close="handleFollowClose" :list="followList" />
  <CustomerDetail width="1200px" :isShowDialog="isShowDrawer" @close="handleDrawerClose" direction="rtl"
    :userId="drawerId" :options="options" @prev="handlePrevCustomer" @next="handleNextCustomer" />
</template>

<script>
import FilterForm from "@/components/filter";
import AllCustomerTable from "@/components/table";
import AllCustomerPagination from "@/components/pagination";
import { computed, onMounted, reactive, ref, watch, onUpdated,nextTick } from "vue";
import { allfilterArr, tableColumn } from "./index.js";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getDictionaries, getUserByDept } from "@/api/method/common";
import { distribute, movetoImportant, movetoPublicPool, movetoRubbish } from "@/api/method/customer";
import { reset } from "@/until";
import { ElButton, ElMessage } from "element-plus";
import { getDistributeUsers } from "@/api/method/system";
import DistributionDialog from "@/components/dialog-form";
import DialogFollowRecords from "@/components/dialog-followRecords";
import { getChannelList } from "@/api/method/channel";
import CustomerDetail from "@/components/drawer-form/index.vue";

export default {
  name: "ALLCustomer",
  props: ["options", "departments"],
  components: {
    FilterForm,
    AllCustomerTable,
    AllCustomerPagination,
    [ElButton.name]: ElButton,
    DistributionDialog,
    DialogFollowRecords,
    CustomerDetail,
  },

  setup(props, context) {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      deptId: null,
      userId: null,
      search: null,
      starStatus: null,
      status: null,
      followDate: null,
      source: null,
      remark: null,
      customerAttr: [],
    });
    const deptList = ref([]);
    const buttons = ref(localStorage.getItem("buttons").split(","));
    const getDepartList = async () => {
      await dispatch("departmentList");
      deptList.value = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
      filters.value[0][0].option = deptList.value;
    };
    const route = useRoute();
    const filters = ref(allfilterArr);

    const unflod = () => {
      filters.value = allfilterArr;
    };
    const timestamp = ref(0)
    watch(
      () => filters.value,
      () => {
        filters.value[0][0].option = props.departments;
        filters.value[0][1].option = userList.value;
        if (filters.value.length > 3) {
          filters.value[1][2].option = props.options.statusList.map((item) => ({
            label: item.label,
            val: item.vlaue,
          }));
          filters.value[1][0].option = props.options.starList.map((item) => ({
            label: item.label,
            val: item.vlaue,
          }));
        }
      },
    );
    watch(
      () => props.departments,
      () => {
        filters.value[0][0].option = props.departments;
      }
    );
    watch(timestamp, (newValue, oldValue) => {
      console.log("新值是" + newValue, "旧址是" + oldValue);
      //console.log("route.query" + route.query)
      if (route.params.customerId) {
        isShowDrawer.value = false;
        nextTick(()=>{
          showCustomerDetails(route.params.customerId);
        });
        
      }
    });
    onUpdated(() => {
      if (route.params.customerId) {
        timestamp.value = route.params.t
      }
    });
    onMounted(() => {
      getList();
      dictionaries();
      getDepartList()
      loadChannelList();

    });
    const pagination = reactive({
      page: 1,
      size: 10,
    });
    const tableData = computed(
      () => (state.allCustomerList && state.allCustomerList.records) || []
    );
    const total = computed(
      () => state.allCustomerList && state.allCustomerList.total
    );
    const getList = () => {
      customerIds.value = [];
      const { followDate, createTime, customerAttr, ...params } = filterData;
      const followStartDate = followDate && followDate[0];
      const followEndDate = followDate && followDate[1];
      const createStartDate = createTime && createTime[0];
      const createEndDate = createTime && createTime[1];

      const room = customerAttr.find((item) => item == 1) ? 1 : 0;
      const accumulation = customerAttr.find((item) => item == 2) ? 1 : 0;
      const social = customerAttr.find((item) => item == 3) ? 1 : 0;
      const car = customerAttr.find((item) => item == 4) ? 1 : 0;
      const enterprise = customerAttr.find((item) => item == 5) ? 1 : 0;
      const policy = customerAttr.find((item) => item == 6) ? 1 : 0;
      const business = customerAttr.find((item) => item == 7) ? 1 : 0;
      const issued = customerAttr.find((item) => item == 8) ? 1 : 0;
      dispatch("allCustomerList", {
        ...pagination,
        followStartDate,
        followEndDate,
        createStartDate,
        createEndDate,
        room,
        accumulation,
        social,
        car,
        enterprise,
        policy,
        business,
        issued,
        ...params,
      });
    };
    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };

    const userList = ref([]);
    const changeDept = async (id) => {
      const res = await getUserByDept(id);
      filterData.userId = null;
      userList.value = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
      filters.value[0][1].option = userList.value;
    };

    const channelList = ref([]);
    const loadChannelList = async () => {
      const res = await getChannelList();

      channelList.value = res.data.map((item) => ({
        label: item.name,
        val: item.id,
      }));

      filters.value[3][0].option = channelList.value;
    }

    const closeUp = () => {
      filters.value = allfilterArr;
    };

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };
    const customerIds = ref([]);
    const userId = ref([]);
    const handleSelectionChange = (arr) => {
      customerIds.value = arr.map((item) => item.id);
      userId.value = arr.map((item) => item.userId);
    };
    const handleDistribution = async () => {
      if (!customerIds.value.length) {
        ElMessage.warning("请勾选客户后操作");
        return;
      }
      isShowDialog.value = true;
      const res = await getDistributeUsers();

      forms[0][0].option = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
    };

    const filterOption = ref(null);
    const dictionaries = async () => {
      const res = await getDictionaries();
      filterOption.value = res.data;
      filters.value[1][2].option = filterOption.value.statusList.map(
        (item) => ({
          label: item.label,
          val: item.vlaue,
        })
      );
      filters.value[1][0].option = filterOption.value.starList.map(
        (item) => ({
          label: item.label,
          val: item.vlaue,
        })
      );
    };
    const handleImport = async () => {
      if (!customerIds.value.length) {
        ElMessage.warning("请勾选客户后操作");
        return;
      }
      const res = await movetoImportant(customerIds.value);

      if (res.code == 200) {
        ElMessage.success("移入重要客户成功！");
        getList();
        context.emit("lockCustomer");
      } else {
        ElMessage.error(res.msg);
      }
    };

    const handleRubbish = async () => {

      if (!customerIds.value.length) {
        ElMessage.warning("请勾选客户后操作");
        return;
      }

      const res = await movetoRubbish(customerIds.value);
      if (res.code == 200) {
        getList()
      }
    };

    const handlePublicPool = async () => {
      if (!customerIds.value.length) {
        ElMessage.warning("请勾选客户后操作");
        return;
      }
      const res = await movetoPublicPool(customerIds.value);

      if (res.code == 200) {
        ElMessage.success("移入公海成功！");
        getList();
        context.emit("publicPool");
      } else {
        ElMessage.error(res.msg);
      }
    };

    /**分配给弹窗 */
    const isShowDialog = ref(false);
    const handleClose = () => (isShowDialog.value = false);
    const forms = reactive([
      [
        {
          label: "分配给",
          type: "select",
          attr: "userId",
          placeholder: "请选择销售人员",
          column: 24,
          labelWidth: "120px",
          multiple: true,
          option: [],
        },
      ],
    ]);
    const formData = reactive({
      userId: null,
    });
    const handleDistributionSubmit = async () => {
      const res = await distribute({
        userId: formData.userId,
        customerIds: customerIds.value,
      });

      if (res.code == 200) {
        ElMessage.success("分配成功！");
        getList();
        context.emit("myCustomer");
        handleClose();
      } else {
        ElMessage.error(res.msg);
      }
    };
    const isShowFollow = ref(false);
    const followList = ref([]);

    const followRecords = (row) => {
      isShowFollow.value = true;
      followList.value = row.followRecords;
    };
    const handleFollowClose = () => (isShowFollow.value = false);

    const isShowDrawer = ref(false);

    const allOptions = computed(() => props.options);
    const handleDrawerClose = () => (isShowDrawer.value = false);
    const drawerId = ref(null);
    const handleNameDrawer = (row) => {
      isShowDrawer.value = true;
      drawerId.value = row.id;
    };
    const showCustomerDetails = (id) => {
      isShowDrawer.value = true;
      drawerId.value = id;
    }
    /**客户详情-上一个和下一个 */
    const handleNextCustomer = () => {
      const index =
        state.allCustomerList.records.findIndex(
          (item) => item.id == drawerId.value
        ) + 1;
      if (index == state.allCustomerList.records.length) {
        ElMessage.error("已经是最后一个客户了！");
        return;
      }
      drawerId.value = state.allCustomerList.records[index].id;
    };
    const handlePrevCustomer = () => {
      const index =
        state.allCustomerList.records.findIndex(
          (item) => item.id == drawerId.value
        ) - 1;
      if (index < 0) {
        ElMessage.error("已经是第一个客户了！");
        return;
      }
      drawerId.value = state.allCustomerList.records[index].id;
    };

    return {
      filters,
      filterData,
      unflod,
      closeUp,
      handleSubmit,
      handleReset,
      changeDept,
      tableData,
      tableColumn,
      total,
      handleSelectionChange,
      customerIds,
      getList,
      handleDistribution,
      handleImport,
      handleRubbish,
      handlePublicPool,
      isShowDialog,
      forms,
      formData,
      handleDistributionSubmit,
      handleClose,
      followRecords,
      isShowFollow,
      followList,
      handleFollowClose,
      handleNameDrawer,
      handleDrawerClose,
      isShowDrawer,
      drawerId,
      allOptions,
      buttons,
      currentChange,
      sizeChange,
      handlePrevCustomer,
      handleNextCustomer,
      showCustomerDetails,
      timestamp,
    };
  },
};
</script>

<style>

</style>