<template>
  <el-dialog
    title="跟进记录"
    :width="width"
    v-model="showDialog"
    :before-close="handleColse"
  >
    <ul class="remark-block">
      <li
        v-for="(item, index) in followList"
        :key="index"
      >
        <div class="avatar-header">
          <div class="avatar">{{item.username}}</div>
          <div>
            <div class="header">
              <div class="title">{{item.username}}</div>
              <div class="time">{{item.createTime}}</div>
            </div>

          </div>
        </div>
        <div class="content">
          <div
            class="line-left"
            v-if="index != followList.length - 1"
          >|</div>
          <div
            class="line-left"
            v-else
          ></div>
          <div>
            <div>{{item.message}}</div>
            <div><audio
                v-if="item.voicePath"
                :src="item.voicePath"
                controls="controls"
              >
                Your browser does not support the audio element.
              </audio></div>
          </div>
        </div>
      </li>
      <!-- <li>
        <div class="avatar-header">
          <div class="avatar">李四</div>
          <div class="header">
            <div class="title">张三1</div>
            <div class="time">2021-12-02 18:00:00</div>
          </div>
        </div>
        <div class="content">
          <div class="line-left">|</div>
          <div>李四入住公海</div>
        </div>
      </li> -->
    </ul>
  </el-dialog>
</template>

<script>
import { ElDialog } from "element-plus";
import { computed } from "vue";

export default {
  name: "DialogFilter",
  props: ["width", "list", "isShowDialog"],
  components: {
    [ElDialog.name]: ElDialog,
  },
  setup(props, context) {
    const showDialog = computed(() => props.isShowDialog);

    const followList = computed(() => props.list);

    const handleColse = () => context.emit("close");

    return {
      showDialog,
      followList,
      handleColse,
    };
  },
};
</script>

<style lang="scss">
.remark-block {
  padding: 0 20px;
  li {
    margin-bottom: 10px;
    .avatar-header {
      display: flex;
    }
    .avatar {
      width: 40px;
      height: 40px;
      background-color: #406fff;
      border-radius: 50%;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }
    .header {
      display: flex;
      margin-top: 10px;
    }
    .time {
      color: #999;
      margin-left: 10px;
    }
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .line-left {
      min-width: 50px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content {
      color: #666;
      margin-top: 10px;

      display: flex;
    }
  }
}
</style>